import { Injectable } from '@angular/core';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {ReviewerProfileComponent} from '../components/reviewer-profile/reviewer-profile.component'
@Injectable({
  providedIn: 'root',
})
export class ReviewerDetailDialogService {
  reviewerDetailRef: DynamicDialogRef | undefined;
  constructor(private dialogService:DialogService) { }

  openDialog(reviewerId:string){
    this.reviewerDetailRef = this.dialogService.open(ReviewerProfileComponent, {
      data: {reviewerId:reviewerId},
      header: 'Candidate Profile',
      width: '80vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
  }
}
