import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RatingModule } from 'primeng/rating';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { HeadingComponent } from '../../../shared/components/heading/heading.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserClientVm } from '../../../store/user/user.interface';
import { UserService } from '../../services/user.service';
import { ReviewerService } from '../../services/reviewer.service';
import { HelperService } from '../../services/helper.service';
import { DocumentService } from '../../services/document-service';
import { ToastService } from '../../services/toast.service';
import { ReviewerProfile } from '../../../store/reviewer/reviewer.interface';
import { constants } from '../../constants/constants';
import { CommonModule } from '@angular/common';
import { Document } from '../../../modules/reviewer/profile/profile.interface';
import { AddToTalentPoolListComponent } from '../add-to-talent-pool-list/add-to-talent-pool-list.component'
@Component({
  selector: 'app-reviewer-profile',
  standalone: true,
  imports: [HeadingComponent, AvatarModule, RatingModule, TableModule, FormsModule, ButtonModule, InputTextareaModule, CommonModule, AddToTalentPoolListComponent],
  templateUrl: './reviewer-profile.component.html',
  styleUrl: './reviewer-profile.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ReviewerProfileComponent implements OnInit {
  curretnUser!: UserClientVm;
  value: number = 5;
  cities: any;
  text: any = "";
  fullText: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at euismod ipsum, vel aliquet est. Mauris id blandit lorem. Duis pulvinar tincidunt nibh at accumsan. Nulla vel ultrices erat vel.';
  truncatedText: string = '';
  showMore: boolean = false;
  maxLength: number = 200;
  headingButton = {
    url: '/client/project/create',
    label: 'INVITE CANDIDATE'
  }
  newNote: string = '';
  user!: ReviewerProfile;
  pastProjects: any[] = [];
  docuSignDocuments: any[] = [];
  firmReviewerDocuments: any[] = [];
  notes: any[] = [];
  talentPoolAssigned: any[] = [];
  showTalentPoolListing: boolean = false;
  secureBackgroundStatus = constants.secureBackgroundStatus;
  constructor(private reviewerDetailRef: DynamicDialogRef, public config: DynamicDialogConfig, private userService: UserService,
    private reviewerService: ReviewerService, private helperService: HelperService, private documentService: DocumentService,
    private toast: ToastService
  ) {

  }
  ngOnInit() {
    this.curretnUser = this.userService.getSessionUser();
    this.getReviewerDetails();
    this.getRevewerPastProjects();
    this.getReviewerDocuSignDocuments();
    this.getFirmReviewerDocuments();
    this.getAllTalentPoolByReviewerId();
    this.truncatedText = this.fullText.length > this.maxLength
      ? this.fullText.slice(0, this.maxLength) + '...'
      : this.fullText;
    this.cities = [
    ];
  }
  toggleShow() {
    this.showMore = !this.showMore;
  }
  getAllTalentPoolByReviewerId() {
    this.reviewerService.getAllTalentPoolByReviewerId(this.config.data.reviewerId).subscribe((resp: any) => {
      this.talentPoolAssigned = resp;

    })
  }
  getFirmReviewerDocuments() {
    const params = {
      reviewerId: this.config.data.reviewerId,
      firmId: this.curretnUser.firm._id
    };
    this.reviewerService.getFirmReviewerDocuments(params).subscribe((resp: any) => {

      resp.map((d: any) => {
        d.url = this.documentService.getDocumentPathById(d._id);
      })
      this.firmReviewerDocuments = resp;
    })
  }
  getReviewerDocuSignDocuments() {
    this.reviewerService.getReviewerDocuSignDocuments(this.config.data.reviewerId, this.curretnUser.firm._id).subscribe((resp: any) => {
      this.docuSignDocuments = resp.filter((d: any) => {
        return d.firm === this.curretnUser.firm._id;
      })

    })
  }
  getRevewerPastProjects() {
    this.reviewerService.getReviewerAllPastProjects(this.config.data.reviewerId).subscribe({
      next: (resp) => {this.pastProjects = resp;}
    })
  }
  getReviewerDetails() {
    this.reviewerService.getReviewerProfile(this.config.data.reviewerId, this.curretnUser.firm._id).subscribe((resp: ReviewerProfile) => {
      this.user = resp;
      this.user.profile.contact.home = this.helperService.formatPhoneNo(this.user.profile.contact.home);
      this.user.documents.map((d: any) => {
        if (d.documentType !== constants.documentTypes.resume)
          d.url = this.documentService.getDocumentPathById(d._id);
      })
    })
  }
  status(data: any): string {
    if (data.secureBackgroundResult === this.secureBackgroundStatus.meetsRequirement) {
      return 'Verified';
    } else if (data.secureBackgroundStatus === this.secureBackgroundStatus.pending || data.secureBackgroundResult === this.secureBackgroundStatus.needsFurtherReview) {
      return 'Verification in progress';
    } else if (data.secureBackgroundResult === this.secureBackgroundStatus.doesntMeetsRequirement) {
      return 'Not verified';
    }
    return data.secureBackgroundResult;
  }

  openDocuSignDocument(envelopId: string, documentId: string, documentName: string) {
    this.reviewerService.viewEnvelopeDocument(envelopId, documentId).subscribe((res: any) => {
      let uri = "data:application/octet-stream," + window.escape(res.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = uri;
      downloadLink.download = documentName + ".pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  addNote() {
    const note = this.newNote;
    this.reviewerService.addNote( this.config.data.reviewerId, note).subscribe((resp: any) => {
      this.newNote = '';
      this.user.notes.push(resp);
    })
  }

  onUploadDocument(event: any, type: string): void {
    const file = event.target.files[0];
    const opts = {
      documentType: type,
      firmId: this.curretnUser.firm._id,
      reviewerId: this.config.data.reviewerId
    };
    this.uploadDocument(file, opts);
  }


  uploadDocument(file: any, opts: any) {

    this.documentService.uploadDocument(file, opts).subscribe((d: any) => {
      this.toast.showSuccess('Document uploaded successfully');
      this.getFirmReviewerDocuments();
    })
  }

  editDocument(event: any, type: string, data: Document) {
    const addDocument = { file: event.target.files[0], opts: {} };
    addDocument.opts = {
      documentType: type,
      firmId: this.curretnUser.firm._id,
      reviewerId: this.config.data.reviewerId
    };
    this.deleteDocment(data._id, true, addDocument)
  }
  deleteDocment(id: string, edit?: boolean, data?: any) {
    this.documentService.deleteDocument(id).subscribe((d: any) => {
      if (edit) {
        this.uploadDocument(data.file, data.opts);
      } else {
        this.toast.showSuccess('Document deleted successfully');
        this.getFirmReviewerDocuments();
      }
    })
  }

  showTalentPool() {
    this.showTalentPoolListing = !this.showTalentPoolListing
  }
}
